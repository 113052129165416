import { https } from "./api";

export default class {
  constructor() {
    this.instance = https();
  }

  getFamilies = () => {
    return this.instance({
      method: "GET",
      url: "/families",
    });
  };
  addFamily(data) {
    return this.instance({
      method: "POST",
      url: "/families",
      data,
    });
  }
  getUnits(maker = false) {
    return this.instance({
      method: "GET",
      url: `/units?maker=${maker}`,
    });
  }
  getUnit(id) {
    return this.instance({
      method: "GET",
      url: `/units/${id}`,
    });
  }
  getProfiles() {
    return this.instance({
      method: "GET",
      url: "/profiles",
    });
  }
  getUsers() {
    return this.instance({
      method: "GET",
      url: "/users",
    });
  }

  removeUser(uuid) {
    return this.instance({
      method: "DELETE",
      url: `/users/${uuid}`,
    });
  }

  addUser(data) {
    return this.instance({
      method: "POST",
      url: "/users",
      data,
    });
  }

  updateUser(data, uuid) {
    return this.instance({
      method: "PUT",
      url: `/users/${uuid}`,
      data,
    });
  }

  addComment(uuid, data) {
    return this.instance({
      method: "POST",
      url: `/orders/${uuid}/comments`,
      data,
    });
  }

  getComments(uuid) {
    return this.instance({
      method: "GET",
      url: `/orders/${uuid}/comments`,
    });
  }

  resetUserPassword(data) {
    return this.instance({
      method: "PATCH",
      url: `/users/reset-password`,
      data,
    });
  }

  forgotPassword(data) {
    return this.instance({
      method: "POST",
      url: `/users/forgot-password`,
      data,
    });
  }

  saveForgotPassword(data) {
    return this.instance({
      method: "POST",
      url: `/users/save-forgot-password`,
      data,
    });
  }

  addRequest(data) {
    return this.instance({
      method: "POST",
      url: "/orders/solicitation",
      data,
    });
  }

  getOrder(orderId) {
    return this.instance({
      method: "GET",
      url: `/orders/${orderId}`,
    });
  }

  getDuplicity(uuid, qtd) {
    return this.instance({
      method: "GET",
      url: `/orders/duplicity?uuid=${uuid}&qtd=${qtd}`,
    });
  }

  getUsersWithParams(region = null, unitId = null, profileId = null) {
    let url = "/users/dropdown?";
    let hasParams = false;
    if (region) {
      url = url + `region=${region}`;
      hasParams = true;
    }
    if (unitId) {
      if (hasParams) url = url + "&";
      url = url + `unitId=${unitId}`;
      hasParams = true;
    }
    if (profileId) {
      if (hasParams) url = url + "&";
      url = url + `profileId=${profileId}`;
    }

    return this.instance({
      method: "GET",
      url: url,
    });
  }

  getRegions() {
    return this.instance({
      method: "GET",
      url: `/units/regions`,
    });
  }

  getOrders(
    onlySolicitations = false,
    myUnit = false,
    myMakeUnit = false,
    mine = false,
    code = "",
    phaseId = "",
    pending = false
  ) {
    return this.instance({
      method: "GET",
      url: `/orders?onlySolicitations=${onlySolicitations}&myUnit=${myUnit}&myMakeUnit=${myMakeUnit}&mine=${mine}&pending=${pending}&code=${code}&phaseId=${phaseId}`,
    });
  }

  getOrderHistory(uuid) {
    return this.instance({
      method: "GET",
      url: `/orders/${uuid}/history`,
    });
  }

  addOrder(data, uuid) {
    return this.instance({
      method: "POST",
      url: `/orders/${uuid}`,
      data,
    });
  }

  getApplications() {
    return this.instance({
      method: "GET",
      url: "/applications",
    });
  }

  getClassifications() {
    return this.instance({
      method: "GET",
      url: "/classifications",
    });
  }

  getAreas() {
    return this.instance({
      method: "GET",
      url: "/areas/dropdown",
    });
  }

  getSubAreas(areaId) {
    return this.instance({
      method: "GET",
      url: `/areas/dropdown/${areaId}`,
    });
  }

  getMaterials() {
    return this.instance({
      method: "GET",
      url: "/materials",
    });
  }

  getImpacts() {
    return this.instance({
      method: "GET",
      url: "/impacts",
    });
  }

  getManufactures() {
    return this.instance({
      method: "GET",
      url: "/manufactures",
    });
  }

  getEquipmentsByArea(areaId) {
    return this.instance({
      method: "GET",
      url: `/areas/${areaId}/equipments`,
    });
  }

  submitOrder(data) {
    return this.instance({
      method: "POST",
      url: "/orders",
      data,
    });
  }

  getPhases() {
    return this.instance({
      method: "GET",
      url: "/phases",
    });
  }

  updatePhase(uuid, data) {
    return this.instance({
      method: "PATCH",
      url: `/orders/${uuid}/phase`,
      data,
    });
  }

  import(data) {
    return this.instance({
      method: "POST",
      url: `/projects/import`,
      data,
    });
  }

  addProjectImage(uuid, data) {
    return this.instance({
      method: "PATCH",
      url: `/projects/${uuid}/upload`,
      data,
    });
  }

  getProjects(code = "", available = true, myMakeUnit = false) {
    return this.instance({
      method: "GET",
      url: `/projects?code=${code}&available=${available}&myMakeUnit=${myMakeUnit}`,
    });
  }

  getProjectsPerPage(page = 1, limit = 30) {
    return this.instance({
      method: "GET",
      url: `/projects/paginate?page=${page}&limit=${limit}`,
    });
  }

  getKanBan(filters) {
    let url = "?";

    url = url + `inova=${filters.inova}`;

    if (filters.my) {
      url = url + `&my=${filters.my}`;
    }

    if (filters.requestUnitId) {
      url = url + `&requestUnitId=${filters.requestUnitId}`;
    }

    if (filters.makeUnitId) {
      url = url + `&makeUnitId=${filters.makeUnitId}`;
    }

    if (filters.region) {
      url = url + `&region=${filters.region}`;
    }

    if (filters.classificationId) {
      url = url + `&classificationId=${filters.classificationId}`;
    }

    if (filters.designerId) {
      url = url + `&designerId=${filters.designerId}`;
    }

    if (filters.createdAtFrom) {
      url = url + `&createdAtFrom=${filters.createdAtFrom}`;
    }

    if (filters.createdAtTo) {
      url = url + `&createdAtTo=${filters.createdAtTo}`;
    }

    if (filters.code) {
      url = url + `&code=${filters.code}`;
    }
    if (filters.material) {
      url = url + `&material=${filters.material}`;
    }

    return this.instance({
      method: "GET",
      url: `/kanban${url}`,
    });
  }

  updateProject(data, uuid) {
    return this.instance({
      method: "PATCH",
      url: `/orders/${uuid}`,
      data,
    });
  }

  releaseProject(data, uuid) {
    return this.instance({
      method: "PATCH",
      url: `/projects/${uuid}`,
      data,
    });
  }

  getHomologationUnits(projectUuid) {
    return this.instance({
      method: "GET",
      url: `/homologation-units/project/${projectUuid}`,
    });
  }

  getPendingOrders() {
    return this.instance({
      method: "GET",
      url: `/orders/pending`,
    });
  }

  exportProjects() {
    return this.instance({
      method: "GET",
      url: `/projects/export`,
      responseType: "blob",
    });
  }
}
